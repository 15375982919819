.App {
  display: flex;
  background-image: url(../public/brick_wall.jpg);
  background-size: cover;
  min-height: 100vh;
  justify-content: center;
}

.App-logo {
  height: 90vmin;
  width: 100vmin;
  pointer-events: none;
  background-image: url(../public/aerosol-rebels_logo.png);
  background-size: cover;
}

.App-logo:hover {
  filter: drop-shadow(8px -11px 15px green);
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}
body {
  margin: 1em;
  font: 1.2em/1.3em 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', 'Roboto-Light', 'Roboto Light', 'Roboto', 'Segoe UI Web Light', 'Segoe UI Light', 'Segoe UI Web Regular', 'Segoe UI', Helvetica, Arial, sans-serif;
  color: #fff;
}
h1 {
  font-weight: normal;
  text-align: center;
}
p {
  margin: 0 0 1em 0;
}

#content {
  max-width: 1300px;
  width: 100%;
}

header,
footer {
  margin: 4px;
}

header, footer {
  display: flex;
  min-height: 80px;
  justify-content: center;
}

footer > a {
  color: white;
  margin-left: 5px;
}